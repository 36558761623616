@import "~antd/dist/antd.less";

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
		"Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
		"Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-family: "Mulish", sans-serif;

	background: #f0f2f5;
	*::-webkit-scrollbar-track {
		background-color: rgba(0, 0, 0, 0);
	}
	*::-webkit-scrollbar-active {
		background-color: lightgrey;
	}
	*::-webkit-scrollbar {
		height: 4px;
		width: 8px;
		background-color: rgba(0, 0, 0, 0);
	}
	*::-webkit-scrollbar-thumb {
		border-radius: 4px !important;
		background-color: lightgrey;
	}
	*::-webkit-scrollbar-corner {
		background-color: rgba(0, 0, 0, 0);
	}
}

a {
	color: #444;
	&:hover {
		color: #9665e3;
	}
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

@primary-color: #6b4eb6;@secondary-color: #e2b94e;@success-color: #1da57a;@box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
	0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);@background-color: #ffffff;