.login-container {
	background: url("../img/point.png"),
		linear-gradient(180deg, #0b2540 -1.05%, #0c1b2c 43.79%) !important;
	min-height: 100vh !important;

	.login-logo {
		position: absolute;
		top: 10vh;
		margin: auto;
		width: 200px;
		left: calc(50vw - 100px);
	}

	.login-card {
		background-color: white;
		position: absolute;
		margin: auto;
		top: calc(50vh - (276px / 2));
		left: calc(50vw - (464px / 2));

		padding: 32px;

		.title {
			text-align: center;
		}
		.or-signup {
			color: #8b8b8b;
			text-align: center;
		}
		.button-submit {
			margin: auto;
			display: block;
			margin-top: 24px;
		}
	}

	.ant-form-item-required {
		&::before {
			display: none !important;
		}
	}
}

@primary-color: #6b4eb6;@secondary-color: #e2b94e;@success-color: #1da57a;@box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
	0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);@background-color: #ffffff;