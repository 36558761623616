.route-column-primary {
	margin-top: 2px;
	padding: 10px;
	border-radius: 5px;
	color: white;
	background-color: @primary-color;
}

.route-column-link {
	margin-top: 2px;
	padding: 10px;
	border-radius: 5px;
	color: white;
	background-color: @success-color;
}

@primary-color: #6b4eb6;@secondary-color: #e2b94e;@success-color: #1da57a;@box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
	0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);@background-color: #ffffff;